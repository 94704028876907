.App {
  text-align: center;
  color: #343534;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.content {
  display: flex;
  height: 100vh;
}

.content img {
  max-height: 100%;
  display: block;
}

@media (max-width: 768px) {
  .cnt {
    overflow: auto;
  }
  .content {
    height: auto;
  }

  .scrollmagic-pin-spacer {
    padding-bottom: 0 !important;
    min-height: auto !important;
  }
}
.trails-text {
  position: relative;
  width: 100%;
  font-family: Brush Script MT, Brush Script Std, cursive;
  color: #212a2f;
  font-weight: 800;
  text-transform: uppercase;
  will-change: transform, opacity;
  overflow: hidden;
  display: block;
}

.trails-text > span {
  overflow: hidden;
  display: block;
}

.content > div {
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
}

.content-block {
  display: flex;
  padding-left: 5vw;
  padding-right: 5vw;
  position: relative;
  margin-bottom: 600px;
  margin-top: 25px;
  max-width: 100%;
}

.intro-image {
  position: relative;
  z-index: 1;
  width: 120%;
  left: -50px;
  top: 100%;
  margin-top: 50px;
}

.intro-image figure {
  margin: 0;
  padding: 0;
}

.intro-image img {
  max-width: 100%;
}

.content-block .intro-text {
  position: relative;
  z-index: 2;
  width: 70%;
  line-height: 1.5;
}
@media (max-width: 768px){
  .content-block{
    margin-top: 50px;
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .intro-text {
    width:100%;
  }
  .intro-image{
    left: -10px;
  }
}


.billboard__container {
  /* height: 100vh; */
}

.billboard {
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.billboard__image {
  height: 90vh;
  width: 100%;
  background-image: url("https://source.unsplash.com/user/rafaelnadai");
  background-repeat: no-repeat;
  background-size: cover;
}

.section-border {
  will-change: transform, opacity;
  /* transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1); */
}

.billboard div {
  max-width: 90%;
}

.billboard img {
  width: 100%;
  height: 900px;
  object-fit: cover;
}
@media only screen and (max-width: 760px) {
  .billboard {
    left: 10%;
    top: 50%;
    transform: translate3d(0%, -50%, 0);
  }
  .content-block{
    margin-bottom: 100px;
  }
  .trails-text{
    font-size: 20px;
    margin-bottom: 0;
  }
}
.footer_link{
  font-weight: 400;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: large;
}
.footer_link_list_item{
  margin-bottom: 15px;
}
.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-item {
  padding-right: 20px;
}
@media only screen and (max-width: 760px) {
  .image-item{
    padding: 5px;
  }
}
.title-base{
  font-size: 36px;
  font-weight: 600;
}
h2{
  font-size: 42px;
  margin-top: 100px;
  margin-bottom: 50px;
  font-weight: normal;
}
@media only screen and (max-width: 760px) {
  h2{
    max-width: 300px;
    margin-top: 50px;
    margin-left: auto;
    font-size: 32px;
    margin-right: auto;
  }
}